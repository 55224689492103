/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p",
    ul: "ul",
    li: "li"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "W lutym 2024 roku została podpisana umowa w ramach inwestycji polegającej na odnowieniu nawierzchni drogi wojewódzkiej nr 269 na odcinku łączącym miejscowości Wola Adamowa i Choceń w województwie kujawsko-pomorskim, w powiecie włocławskim."), "\n", React.createElement(_components.p, null, "W ramach robót zostały zrealizowane między innymi następujące czynności:"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "frezowanie nawierzchni,"), "\n", React.createElement(_components.li, null, "ścinka poboczy,"), "\n", React.createElement(_components.li, null, "naprawa krawędzi jezdni,"), "\n", React.createElement(_components.li, null, "wykonanie warstwy wyrównawczej, wiążącej oraz ścieralnej,"), "\n", React.createElement(_components.li, null, "odnowa i regulacja zjazdów na posesje i w pola,"), "\n", React.createElement(_components.li, null, "remont przepustów pod drogą wojewódzką,"), "\n", React.createElement(_components.li, null, "remont chodników, regulacja poboczy, reprofilacja rowów,"), "\n", React.createElement(_components.li, null, "odtworzenie oznakowania poziomego i pionowego."), "\n"), "\n", React.createElement(_components.p, null, "Termin realizacji przewidywany był na 3 miesiące z wyłączeniem okresu zimowego. Inwestycja została oddana do użytku w lipcu 2024 roku."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
